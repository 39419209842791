import { DATA_TYPE_AIRCRAFTS, DATA_TYPE_ANIMATIONS, DATA_TYPE_BRANDS, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_RESTAURANTS, DATA_TYPE_SERVICES, DATA_TYPE_FAVORITE_POSITIONS, DATA_TYPE_BUILDINGS, DATA_TYPE_FLOORS } from './dataConfig';
export var DISPLAYABLE_TYPES = [DATA_TYPE_AIRCRAFTS, DATA_TYPE_ANIMATIONS, DATA_TYPE_BRANDS, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITORS, DATA_TYPE_RESTAURANTS, DATA_TYPE_SERVICES, DATA_TYPE_FAVORITE_POSITIONS];
export var SHOW_MOBILESPOT_BUTTON = false;
export var START_LOCATION_ON_APP_BOOT = false;
export var MOBIGEO_LOG_LEVEL = 'NONE'; // accepted values: NONE | INFO | DEBUG

export var MAP_TYPE = 'mobigeo';
export var PMR_CAPABILITY = false;
export var TITLE_POI = {
  title: DATA_TYPE_BUILDINGS,
  subTitle: DATA_TYPE_FLOORS
};