import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_EXHIBITORS } from 'data/config/dataConfig';

import { DEFAULT_IMAGE } from 'data/config/ficheConfig';

import { PARTICIPANT_PAGE_KEY } from 'src/pages/pagesKeys';

// import DetailEvents from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailSocialRaw from 'src/components/detail-social/DetailSocialRaw';
import WebsiteRow from 'src/components/fiche/WebsiteRow';
import ContactButton from 'src/components/contact-button/ContactButton';
import List from 'src/components/list/List';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import config from 'data/config/config';

import SaveContactButton from 'src/components/save-contact-button/SaveContactButton';

import '../common-fiche.scss';
import './ParticipantPage.scss';

class ParticipantContent extends PureComponent {
  setScrollableContentEl = (el) => {
    this.contentContainerEl = el;
  };
  scrollTo = (value) => {
    if (this.contentContainerEl) {
      this.contentContainerEl.scrollTop = value;
    }
  };

  onClickOnFavIcon = () => {
    this.props.actions.toggleFavorite(
      this.props.item.id,
      DATA_TYPE_PARTICIPANTS,
      this.props.isFavorite
    );
  };

  showEvents = () => {
    this.props.actions.navigateToItems(
      this.props.item.references[DATA_TYPE_EVENTS],
      DATA_TYPE_EVENTS,
      this.props.item.id,
      DATA_TYPE_PARTICIPANTS
    );
  };

  getTitle = (item) =>
    (item.civility ? item.civility + ' ' : '') +
    (item.firstName ? item.firstName + ' ' : '') +
    (item.lastName ? item.lastName : '');

  render() {
    const item = this.props.item,
      title = this.getTitle(item);

    let imageUrl =
      item.profilePicture && typeof item.profilePicture.value === 'string'
        ? item.profilePicture.value
        : null;
    if (!imageUrl && DEFAULT_IMAGE) {
      imageUrl = DEFAULT_IMAGE[DATA_TYPE_PARTICIPANTS];
    }

    return (
      <div className="fiche participant content-font content-below-apptoolbar">
        {/* title */}
        <DetailFixedTitle
          title={title}
          dataType={DATA_TYPE_PARTICIPANTS}
          isFav={this.props.isFavorite}
          onFavClick={this.onClickOnFavIcon}
          labels={this.props.labels}
        >
          <SaveContactButton
            item={this.props.item}
            labels={this.props.labels}
            actions={this.props.actions}
            profile={this.props.profile}
            pageKey={PARTICIPANT_PAGE_KEY}
          />
        </DetailFixedTitle>

        <div className="content" ref={this.setScrollableContentEl}>
          <div className="all-informations content-font">
            <div className="fiche-row1">
              <div className="fiche-row1-col1">
                {imageUrl && <img className="fiche-photo" src={getUrl('/' + imageUrl)} alt="" />}
              </div>
              <div className="fiche-row1-col2">
                <div className="fiche-perso">
                  {item.type && <div className="fiche-perso-type">{item.type}</div>}
                  {item.position && <div>{item.position}</div>}
                  <DetailSocialRaw data={item.social} actions={this.props.actions} />
                </div>
                <div className="fiche-entreprise">
                  {item.organizationName && <div className="">{item.organizationName}</div>}
                  {item.organizationLogo && (
                    <div className="fiche-logo">
                      <img src={getUrl('/' + item.organizationLogo)} alt="" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="fiche-row2">
              <WebsiteRow website={item.organizationWebsite} actions={this.props.actions} />

              {/*
                                <PhoneRow phone={item.phone} />
                                <EmailRow email={item.email} />
                            */}

              {item.address && (
                <div className="free-row">
                  <div className="prop-img">
                    <div className="prop-left">
                      <div className="fiche-contact-icon">
                        <span className="fa fa-university" />
                      </div>
                    </div>
                    <div
                      className="prop-right"
                      dangerouslySetInnerHTML={{ __html: item.address }}
                    />
                  </div>
                </div>
              )}
            </div>

            <ContactButton participant={item} labels={this.props.labels} />

            {/* events */}
            {/* <DetailEvents
                                items={item.references.events}
                                labels={this.props.labels}
                                onClick={this.showEvents} /> */}
            {Array.isArray(item.events) && item.events.length > 0 && (
              <div>
                <div className="fiche-separator">
                  {this.props.labels.speaker.relatedInterventions}
                </div>
                <List
                  items={item.events}
                  dataType={DATA_TYPE_EVENTS}
                  actions={this.props.actions}
                  labels={this.props.labels}
                  isFastAndUgly={false}
                  displayFavorites={false}
                  pageKey={PARTICIPANT_PAGE_KEY}
                />
              </div>
            )}

            {/* Exhibitor */}
            {item.references && item.references.exhibitor &&
              <div>
                <div className="fiche-separator">
                  {this.props.labels.data.exhibitors.singular}
                </div>
                <List
                  items={[ item.references.exhibitor ]}
                  dataType={DATA_TYPE_EXHIBITORS}
                  actions={this.props.actions}
                  labels={this.props.labels}
                  displayFavorites={false}
                  pageKey={PARTICIPANT_PAGE_KEY}
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ParticipantContent.propTypes = {
  item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isFavorite: PropTypes.bool,
  isLogoFullWidth: PropTypes.bool,
  // Common
  queryString: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default ParticipantContent;
