import _defineProperty from "/Users/fadiazitouni/Documents/prod_front/mspot-texworld-0222/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { AIRCRAFT_PAGE_KEY, EVENT_PAGE_KEY, EXHIBITOR_PAGE_KEY, EXPERIENCE_ITEM_PAGE_KEY, FEDLIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY, LIST_PAGE_KEY, MAP_PAGE_KEY, NEWPRODUCT_PAGE_KEY, RESTAURANT_PAGE_KEY, SERVICE_PAGE_KEY, DOCUNIT_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_TO_PAGE_KEY } from 'src/pages/dataToPageMapping';
import { formatDate, formatTime, formatDateAndTime } from 'src/core/Lang';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';
import { openUrl } from 'src/core/util/JsTools';
import { sendPageView } from 'src/core/analytics/GoogleAnalyticsHelper';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { isAndroid, isIOS } from 'src/core/util/browser';
import { DEFAULT_IMAGE } from './ficheConfig';
import { DATA_TYPE_AIRCRAFT_CATEGORIES, DATA_TYPE_AIRCRAFTS, DATA_TYPE_ANIMATIONS, DATA_TYPE_BRAND_CATEGORIES, DATA_TYPE_BRANDS, DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_FLIGHTS_SCHEDULE, DATA_TYPE_KLIPSO_LEADS_CONTACTS, DATA_TYPE_NEWPRODUCT_CATEGORIES, DATA_TYPE_NEWPRODUCTS, DATA_TYPE_RESTAURANT_CATEGORIES, DATA_TYPE_RESTAURANTS, DATA_TYPE_SERVICE_CATEGORIES, DATA_TYPE_SERVICES, DATA_TYPE_DOCUNITS, DATA_TYPE_DOCUNIT_CATEGORIES, DATA_TYPE_SPEAKERS, DATA_TYPE_PAPER_CATEGORIES, DATA_TYPE_PAPERS, DATA_TYPE_PLACES, DATA_TYPE_PARTICIPANTS, DATA_TYPE_CONTACTED_PARTICIPANTS, DATA_TYPE_CONTACTS, DATA_TYPE_FAVORITE_POSITIONS, CATEGORIES_MAPPING, getSpeakerTitle, getSpeakerSubtitle } from './dataConfig';
var LOG_PREF = '[listConfig] ';
export var ROW_BACKGROUND_COLOR_ON_CLICK = '#efefef';
/**
 * Hide items counter in category list
 * @type {Boolean}
 */

export var HIDE_CATEGORY_ITEMS_COUNTER = true;
/**
 * Display alphabetical index if row count if above
 * @type {Number}
 */

export var LIST_SIDEINDEX_THRESHOLD = 50;
/**
 * Specific threshold for lists of groups (e.g events)
 * @type {Number}
 */

export var LIST_OF_GROUPS_SIDEINDEX_THRESHOLD = 20;
/**
 * List displays only the items beginning with the current alphabetical index
 * @type {Number}
 */

export var ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_ANDROID = 200;
export var ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_IOS = 700;
export var ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_WEB = 1000;
export var getAlphabeticalListByIndexThreshold = function getAlphabeticalListByIndexThreshold() {
  if (isAndroid()) {
    return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_ANDROID;
  }

  if (isIOS()) {
    return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_IOS;
  }

  return ALPHABETICAL_LIST_BY_INDEX_IF_ABOVE_WEB;
}; // Typical use case: search results

export var HIGHLIGHTED_PROPS = ['text', 'textMinor'];
export var DISABLE_FAVORITE_ICON = false;
export var DISABLE_FAVORITE_BY_DATATYPE = [];
/**
 * TypeBar is automatically closed when items count is higher than this
 * @see DataListDialog
 * @type {Number}
 */

export var DIALOG_TYPEBAR_CLOSED_IF_COUNT_ABOVE = 5;
/**
 * Ability to modify the side of the alphabetical index
 * @return {string}
 */

export var getSideIndexPosition = function getSideIndexPosition() {
  return (// On desktop with app displayed in two columns, put the index on the left side
    // to prevent list scrollTop to be modified when cursor moves from left column to right column.
    TwoColumnsModeManager.isEnabled() && global.isCordovaContext === false ? 'left' : 'right'
  );
};

function hasPlaces(row) {
  return Array.isArray(row.places) && row.places.length > 0;
}

function serviceHasContent(service) {
  return !!(service.description || service.phone || service.website || service.email);
}

var stringifyIfSetOrNull = function stringifyIfSetOrNull(value) {
  return value ? String(value) : null;
};
/**
 * Define here the `props` (~ attributes) an element of a list will receive.
 * Every helper function receives as parameter the full object
 *----------------------------------------------------------------------
 * See app-react/src/components/list/ListElement.js for available props
 *----------------------------------------------------------------------
 */


export function elementPropsGetters(dataType, pageKey) {
  var _ref;

  switch (dataType) {
    // NB: generic props are set by List component:
    //       - 'data-id' (data item id)
    //       - 'data-type'
    case DATA_TYPE_PLACES:
      return {
        key: function key(place) {
          return "".concat(place.id, "-").concat(place.memberType, "-").concat(place.memberId);
        },
        text: function text(place) {
          return String(place.memberTitle);
        },
        textMinor: function textMinor(place) {
          return String(place.label);
        },
        textMinorBis: function textMinorBis(place) {
          return String(place.info || '');
        },
        'data-member-type': function dataMemberType(place) {
          return String(place.memberType);
        },
        'data-member-id': function dataMemberId(place) {
          return String(place.memberId);
        },
        'data-member-original-id': function dataMemberOriginalId(place) {
          return String(place.memberOriginalId);
        }
      };

    case DATA_TYPE_ANIMATIONS:
      return {
        text: function text(anim) {
          return String(anim.title);
        },
        'data-original-id': function dataOriginalId(anim) {
          return String(anim.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(anim) {
          return String(anim.contextualPlaceId);
        }
      };

    case DATA_TYPE_BRANDS:
      return {
        text: function text(brand) {
          return String(brand.title);
        },
        textMinor: function textMinor(brand) {
          return brand.lump.subtitle;
        },
        'data-original-id': function dataOriginalId(brand) {
          return String(brand.original_id);
        }
      };

    case DATA_TYPE_SERVICES:
      return {
        text: function text(service) {
          return String(service.title);
        },
        textMinor: function textMinor(service) {
          return service.lump.subtitle;
        },
        // image: service => service.logo_file_name,
        'data-link': function dataLink(service) {
          return service.lump && service.lump.link || null;
        },
        'data-original-id': function dataOriginalId(service) {
          return String(service.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(service) {
          return service.contextualPlaceId;
        },
        'data-has-places': function dataHasPlaces(service) {
          return hasPlaces(service);
        },
        'data-no-content': function dataNoContent(service) {
          return serviceHasContent(service) !== true;
        },
        isClickable: function isClickable(service) {
          return hasPlaces(service) || serviceHasContent(service);
        } // default: true

      };

    case DATA_TYPE_EXHIBITORS:
      return {
        'data-original-id': function dataOriginalId(exh) {
          return String(exh.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(exh) {
          return exh.contextualPlaceId;
        },
        text: function text(exh) {
          return String(exh.title);
        },
        textMinor: function textMinor(exh) {
          return exh.lump && exh.lump.subtitle ? exh.lump.subtitle : null;
        },
        textStyle: function textStyle(exh) {
          return exh.lump && exh.lump.color ? {
            color: exh.lump.color
          } : null;
        },
        className: function className(exh) {
          var classes = [];
          exh.lump && exh.lump.focus !== undefined && exh.lump.focus ? classes.push('display-chevron') : null;

          if (exh.lump) {
            if (exh.lump.bold === '1') {
              classes.push('emphasised');
            }
          } // to be completed


          return classes.join(' ');
        },
        image2: function image2(exh) {
          return exh.lump && exh.lump.focus !== undefined && exh.lump.focus ? exh.lump.focus : null;
        }
      };

    case DATA_TYPE_AIRCRAFTS:
      return {
        text: function text(aircraft) {
          return String(aircraft.title);
        },
        textMinor: function textMinor(aircraft) {
          return aircraft.lump && aircraft.lump.subtitle ? aircraft.lump.subtitle : null;
        },
        'data-original-id': function dataOriginalId(aircraft) {
          return String(aircraft.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(aircraft) {
          return String(aircraft.contextualPlaceId);
        }
      };

    case DATA_TYPE_RESTAURANTS:
      return {
        text: function text(restau) {
          return String(restau.title);
        },
        textMinor: function textMinor(restau) {
          return restau.lump.subtitle;
        },
        'data-original-id': function dataOriginalId(restau) {
          return String(restau.original_id);
        }
      };

    case DATA_TYPE_SPEAKERS:
      return _ref = {
        text: function text(speaker) {
          return getSpeakerTitle(speaker);
        },
        textMinor: function textMinor(speaker) {
          return speaker.lump && speaker.lump.subtitle ? speaker.lump.subtitle : null;
        }
      }, _defineProperty(_ref, "data-".concat(DATA_TYPE_EVENTS), function data(speaker) {
        return speaker.lump ? speaker.lump[DATA_TYPE_EVENTS] : [];
      }), _defineProperty(_ref, "image", function image(speaker) {
        return speaker.photo;
      }), _defineProperty(_ref, "underImage", function underImage(speaker) {
        return speaker.logo;
      }), _defineProperty(_ref, "useForIndexLetter", function useForIndexLetter(speaker) {
        return speaker.lastname;
      }), _ref;

    case DATA_TYPE_EVENTS:
      return {
        'data-id': function dataId(event) {
          return stringifyIfSetOrNull(event.isTaigaEvent ? event.dbId : event.id);
        },
        'data-original-id': function dataOriginalId(event) {
          return stringifyIfSetOrNull(event.original_id);
        },
        'data-contextual-place-id': function dataContextualPlaceId(event) {
          return stringifyIfSetOrNull(event.contextualPlaceId);
        },
        event: function event(_event) {
          return {
            start_date: _event.start_date,
            start_time: _event.start_time,
            end_date: _event.end_date,
            end_time: _event.end_time
          };
        },
        text: function text(event) {
          return String(event.title);
        },
        // textMinor: event => event.event_place_text ? event.event_place_text : null,
        textMinor: function textMinor(event) {
          return event.lump && event.lump.subtitle ? event.lump.subtitle : null;
        },
        isClickable: function isClickable(event) {
          return !(event.isTaigaEvent && !event.dbId);
        }
      };

    case DATA_TYPE_PAPERS:
      return {
        text: function text(np) {
          return String(np.title);
        },
        textMinor: function textMinor(np) {
          return np.lump && np.lump.subtitle ? np.lump.subtitle : null;
        }
      };

    case DATA_TYPE_FLIGHTS_SCHEDULE:
      return {
        'data-id': function dataId(flight) {
          return flight.id;
        },
        text: function text(flight) {
          return flight.aircraft && flight.aircraft.title || 'Unknown aircraft';
        },
        event: function event(flight) {
          var start = flight.time_start.split(':');
          return {
            start_date: Number(flight.date),
            start_time: start.length > 2 ? start.slice(0, 2).join(':') : start.join(':'),
            end_date: Number(flight.date),
            end_time: ''
          };
        },
        textMinor: function textMinor(flight) {
          return flight.time_end;
        },
        // aircraft desc is very long
        isClickable: function isClickable(flight) {
          return flight.hasAircraft;
        }
      };

    case DATA_TYPE_NEWPRODUCTS:
      return {
        text: function text(np) {
          return String(np.title);
        },
        textMinor: function textMinor(np) {
          return np.exhibitor ? String(np.exhibitor.title) : null;
        },
        image: function image(np) {
          return np.photo_file_name;
        }
      };

    case DATA_TYPE_FAVORITE_POSITIONS:
      return {
        'data-id': function dataId(fav) {
          return fav.id;
        },
        text: function text(fav) {
          return fav.title;
        }
      };

    case DATA_TYPE_DOCUNITS:
      return {
        data_id: function data_id(docunit) {
          return docunit.id;
        },
        'data-original-id': function dataOriginalId(docunit) {
          return docunit.original_id;
        },
        text: function text(docunit) {
          return docunit.title;
        },
        image: function image(docunit) {
          return docunit.image ? docunit.image : null;
        }
      };

    case DATA_TYPE_PARTICIPANTS:
    case DATA_TYPE_CONTACTED_PARTICIPANTS:
      return {
        text: function text(p) {
          return "<b>".concat(p.civility ? "".concat(p.civility, " ") : '').concat(p.firstName, " ").concat(p.lastName, "</b>");
        },
        textMinor: function textMinor(p) {
          return p.organizationName;
        },
        textMinorBis: function textMinorBis(p) {
          return p.type;
        },
        image: function image(p) {
          return p.profilePicture && p.profilePicture.value ? p.profilePicture.value : DEFAULT_IMAGE[DATA_TYPE_PARTICIPANTS];
        },
        useForIndexLetter: function useForIndexLetter(p) {
          return p.lastName;
        }
      };

    case DATA_TYPE_CONTACTS:
      return {
        text: function text(p) {
          return "<b>".concat(p.civility ? "".concat(p.civility, " ") : '').concat(p.firstName, " ").concat(p.lastName, "</b>");
        },
        textMinor: function textMinor(p) {
          return formatDateAndTime(p.scanDate);
        },
        textMinorBis: function textMinorBis(p) {
          return p.role;
        },
        image: function image(p) {
          return p.profilePicture && p.profilePicture.value ? p.profilePicture.value : DEFAULT_IMAGE[DATA_TYPE_CONTACTS];
        },
        useForIndexLetter: function useForIndexLetter(p) {
          return p.lastName;
        }
      };

    case DATA_TYPE_KLIPSO_LEADS_CONTACTS:
      return {
        leftImageClassName: function leftImageClassName(contact) {
          return contact.isSync ? 'kl-list-icon-sync fal fa-check' : 'kl-list-icon-notsync far fa-sync';
        },
        text: function text(contact) {
          return contact.Societe ? "".concat(contact.Societe, "<br>") : '';
        },
        textMinor: function textMinor(contact) {
          return (contact.Prenom ? "".concat(contact.Prenom, " ") : '') + (contact.Nom ? "<span style=\"text-transform: uppercase;\">".concat(contact.Nom, "</span>") : '');
        },
        'data-cab': function dataCab(contact) {
          return contact.code;
        },
        useForIndexLetter: function useForIndexLetter(contact) {
          return contact.Societe;
        } // use for side index when data is sorted by company

      };

    case DATA_TYPE_AIRCRAFT_CATEGORIES:
    case DATA_TYPE_BRAND_CATEGORIES:
    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
    case DATA_TYPE_RESTAURANT_CATEGORIES:
    case DATA_TYPE_SERVICE_CATEGORIES:
    case DATA_TYPE_NEWPRODUCT_CATEGORIES:
    case DATA_TYPE_DOCUNIT_CATEGORIES:
    case DATA_TYPE_PAPER_CATEGORIES:
      return {
        counter: function counter(cat) {
          return cat.counter;
        },
        text: function text(cat) {
          return String(cat.title);
        },
        image: function image(cat) {
          return cat.lump && cat.lump.logo;
        },
        textMinor: function textMinor(cat) {
          return cat.lump.subtitle || null;
        }
      };

    default:
      console.error("".concat(LOG_PREF, "No helpers specified for type `").concat(dataType, "`"));
  }
}
/**
 * Indicate if user is redirected to map (show POI) when selecting an item
 * @param  {string} dataType
 * @return {boolean}
 */

export function shouldRedirectToMap(dataType) {
  switch (dataType) {
    case DATA_TYPE_BRANDS:
      // Show POI on map
      return true;

    default:
      return false;
  }
}
/**
 * Define here what should happen when a note list entry is clicked
 * @param {DOM element} liEl
 * @param {object} actions: redux actions (connected to page instance)
 * @param {object} labels
 * @param {string} pageKey (optional related page identifier)
 */

export function onClickOnNote(liEl, actions, labels, pageKey) {
  actions.showNoteModal(liEl.title, liEl.dataset.id, liEl.dataset.type, liEl);
}
/**
 * Define here what should happen when a list entry (<ListElements />) is clicked
 * @param {DOM element} liEl
 * @param {object} actions: redux actions (connected to page instance)
 * @param {object} labels
 * @param {string} pageKey (optional related page identifier)
 */

export function onClickOnLi(liEl, actions, labels, pageKey) {
  var _liEl$dataset = liEl.dataset,
      id = _liEl$dataset.id,
      type = _liEl$dataset.type,
      originalId = _liEl$dataset.originalId; // Auto parse id

  if (/^\d+$/.test(id)) {
    id = parseInt(id, 10);
  }

  switch (type) {
    case DATA_TYPE_AIRCRAFT_CATEGORIES:
    case DATA_TYPE_BRAND_CATEGORIES:
    case DATA_TYPE_EVENT_CATEGORIES:
    case DATA_TYPE_EXHIBITOR_CATEGORIES:
    case DATA_TYPE_RESTAURANT_CATEGORIES:
    case DATA_TYPE_SERVICE_CATEGORIES:
    case DATA_TYPE_NEWPRODUCT_CATEGORIES:
    case DATA_TYPE_PAPER_CATEGORIES:
    case DATA_TYPE_DOCUNIT_CATEGORIES:
      actions.categoryClicked(id, type);
      break;

    case DATA_TYPE_AIRCRAFTS:
    case DATA_TYPE_ANIMATIONS:
    case DATA_TYPE_BRANDS:
    case DATA_TYPE_EXHIBITORS:
    case DATA_TYPE_EVENTS:
    case DATA_TYPE_NEWPRODUCTS:
    case DATA_TYPE_RESTAURANTS:
    case DATA_TYPE_PAPERS:
    case DATA_TYPE_DOCUNITS:
    case DATA_TYPE_PARTICIPANTS:
    case DATA_TYPE_CONTACTS:
    case DATA_TYPE_SPEAKERS:
      actions.genericItemNavigation(type, id, originalId);
      break;

    case DATA_TYPE_CONTACTED_PARTICIPANTS:
      actions.genericItemNavigation(DATA_TYPE_PARTICIPANTS, id, originalId);
      break;

    case DATA_TYPE_SERVICES:
      if (liEl.dataset.link) {
        openUrl(getUrl(liEl.dataset.link, !global.isCordovaContext, // use http
        true // absolute
        ));
        sendPageView(liEl.dataset.link);
      } else if (liEl.dataset.noContent === 'true' && liEl.dataset.hasPlaces === 'true') {
        actions.showOnePoiOnMap({
          type: type,
          id: id,
          originalId: originalId
        });
      } else {
        actions.genericItemNavigation(type, id, originalId);
      }

      break;

    case DATA_TYPE_PLACES:
      var searchType = liEl.parentNode.dataset.searchType;
      var textMinor = liEl.querySelector('.text-minor');
      actions.searchedPlaceSelected(searchType, {
        placeId: id,
        type: liEl.dataset.memberType,
        id: liEl.dataset.memberId,
        originalId: liEl.dataset.memberOriginalId,
        text: liEl.getAttribute('title'),
        textMinor: textMinor ? textMinor.textContent : null
      });
      break;

    case DATA_TYPE_FAVORITE_POSITIONS:
      actions.showOnePoiOnMap({
        type: type,
        id: id,
        originalId: null
      });
      break;

    case DATA_TYPE_FLIGHTS_SCHEDULE:
      actions.flightScheduleClicked(id);
      break;

    case DATA_TYPE_KLIPSO_LEADS_CONTACTS:
      actions.klipsoLeadsEnableFormScreen({
        code: liEl.dataset.cab
      });
      break;

    default:
      console.error("".concat(LOG_PREF, "Nothing defined for click on type `").concat(type, "`"));
  }
}