module.exports = require("deepmerge")(require("./config.default.js"), {
  version: {
    codepushDeploymentVersion: {
      iOS: "",
      android: ""
    }
  },
  native: {
    appleTeamId: "2BAP3P29V2",
    name: {
      default: "Texworld Evolution Paris_D"
    },
    id: "com.messefrankfurt.Texworld.dev",
    playStoreUrl: ''
  },
  codePush: {
    android: {
      APP_SECRET: "",
      CodePushDeploymentKey: ""
    },
    iOS: {
      APP_SECRET: "",
      CodePushDeploymentKey: ""
    }
  },
  web: {
    url: "texworld-dev.mobile-spot.com"
  }
});