var defaultConf = require('./config-default');

var BO_URL = 'texworld0222-data-dev.mobile-spot.com';
var BO_TAIGA_URL = ' https://texworld0222.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'texworld-node-backend-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/messe-frankfurt-france/texworld/texworld0222/texworld0222-data-dev.mobile-spot.com',
  // no trailing '/'//
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/messe-frankfurt-france/texworld/texworld0222/texworld-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-34',
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile/mot-de-passe-oublie.htm")
  },
  projectId: '1056757301402',
  // FCM sender id
  appId: 'D235D-067BF',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  CODEPUSH: {
    FEATURE_ENABLED: false
  },
  SOCIAL: {
    FEATURE_ENABLED: true,
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },

    /*  FACEBOOK: {
       POST_PER_PAGE: 5,
     }, */
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  }
});